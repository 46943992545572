import React from 'react'
//import "./index.css";

const Termsofuse = () => {
  return (<>
  {/* <div
          style={{
            // marginTop:"-44.5rem",
            marginLeft:"6.1rem",
            marginTop:"2rem",
            
          }}
        >
          <Logo />
        </div> */}
  
  <div className='Privavy-Main' style={{width: "100%",
  backgroundColor: "white",
  paddingTop: "100px",
  padding: "100px 80px 10px 80px"}}>
    <h2>Terms of Use</h2>
      <p>How We Utilize Information Collected in Our Apps</p>

      <p>
        We collect this information to enhance the performance and reliability of our apps and gain
        a better understanding of general user preferences. None of this information is linked in
        any way to individuals or any other data we may acquire. We do not share, sell, or provide
        this information to other individuals or companies, and we do not rent or sell your email
        address.
      </p>

      <p>
        Our mobile applications adhere to the Children's Online Privacy Protection Act ("COPPA"). If
        a user identifies as a child under the age of 13 through a support request or feedback, we
        will neither collect, store, nor use any personal information of such a user and will
        securely delete it.
      </p>

      <p>
        We take precautions to filter sensitive and inappropriate ad categories for children.
        However, there may be rare instances where advertisers misclassify ads, which is beyond our
        control.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        <a href="https://www.aityl.com" style={{textDecoration:"none"}}>aityl.com</a> reserves the right to update this privacy
        policy at any time. When we do, we will modify the updated date at the home page.
        Users are encouraged to regularly check this page for any changes to stay informed about how
        we protect the collected personal information. You acknowledge and agree that it is your
        responsibility to periodically review this privacy policy and stay aware of any
        modifications.
      </p>
      <p>For more information, please visit the following links:</p>
      <ul>
        <li>
          <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" style={{textDecoration:"none"}}>
            Apple iTunes Developer Standard EULA
          </a>
        </li>
        <li>
          <a href="https://play.google.com/about/play-terms/index.html" style={{textDecoration:"none"}}>
            Google Play Terms of Service
          </a>
        </li>
      </ul>
      <h2>Cookies</h2>
      <p>
        When you visit our website, we may send cookies to your computer that uniquely identify your
        browser. We use cookies to enhance our service quality by storing user preferences and
        tracking usage information. Most browsers allow you to disable cookies or set your browser
        to alert you when cookies are being sent to your computer.
      </p>
      </div>
  
      </>)
}
const Logo = () => {
  return (
    <>
      <a  href="/" style={{textDecoration:"none",fontSize:"2rem",color:"black" }}>
        {/* <img
          src={require("../../Assets/New Horse.png")}
          alt=""
          style={{
            height: 53,
          }}
        /> */}
        <b>AITYL</b>
      </a>
    </>
  );
};

export default Termsofuse