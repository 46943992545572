import "./index.css";
import React from "react";

import img from "../../../Assets/frontend/Image.png";
import img1 from "../../../Assets/frontend/Image-1.png";
import img2 from "../../../Assets/frontend/Image-2.png";
import img3 from "../../../Assets/frontend/d3.png";
import img4 from "../../../Assets/frontend/Image-4.png";
import img5 from "../../../Assets/frontend/Image-5.png";
import img6 from "../../../Assets/frontend/Image-6.png";
import img7 from "../../../Assets/frontend/powerbi.png";
import img8 from "../../../Assets/frontend/tableau.png";

import backend from "../../../Assets/backend/xcode.png";
import backend1 from "../../../Assets/backend/Image-1.png";
import backend2 from "../../../Assets/backend/Image-2.png";
import backend3 from "../../../Assets/backend/Image-3.png";
import backend4 from "../../../Assets/backend/Image-4.png";
import backend5 from "../../../Assets/backend/Image-5.png";
import backend6 from "../../../Assets/backend/Image-6.png";
import backend7 from "../../../Assets/backend/pngwing.com.png";
import backend8 from "../../../Assets/backend/Image-8.png";

import mobile from "../../../Assets/mobile/Image.png";
import mobile1 from "../../../Assets/mobile/Image-1.png";
import Kivy from "../../../Assets/file-type-kivy.512x512.png";
import mobile2 from "../../../Assets/mobile/Image-2.png";
import mobile3 from "../../../Assets/mobile/Image-3.png";
import mobile4 from "../../../Assets/mobile/Image-4.png";
import mobile5 from "../../../Assets/mobile/Image-5.png";
import mobile6 from "../../../Assets/mobile/Image-6.png";
import mobile7 from "../../../Assets/mobile/Image-7.png";
import mobile8 from "../../../Assets/mobile/Image-8.png";

import design from "../../../Assets/design/mysql-icon.png";
import oracleupdate from "../../../Assets/oracle-logo-2.png";
import design1 from "../../../Assets/sqlserver.png";
import design2 from "../../../Assets/design/mongodb-icon.png";
import design3 from "../../../Assets/design/cassandra (1).png";
import design4 from "../../../Assets/design/aws.png";
import flask from "../../../Assets/kindpng_1882416.png";
import design5 from "../../../Assets/design/sqlite.png";
import design6 from "../../../Assets/design/oracle.png";
import design7 from "../../../Assets/aws.png";
import uday from "../../../Assets/post.png";
import ionic from "../../../Assets/ionic.png";
import design8 from "../../../Assets/Azure-Cloud_Logo.png";
import design10 from "../../../Assets/db2.png";
import design9 from "../../../Assets/gc.png";
import design11 from "../../../Assets/Google_Cloud_Platform-Logo.png";
import design12 from "../../../Assets/Google_Cloud_Platform-Logo.png";
import design13 from "../../../Assets/Google_Cloud_Platform-Logo.png";


const Technologies = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        // paddingLeft: 50,
        // paddingRight: 50,
        justifyContent: "center",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        
      }}
    >
      <div id="tech"></div>
      <p className="techWeWorkedOnText">The tools and technologies with which we have experience</p>
      <div className="techContainerMega" style={{}}>
        <EachTechCard
          title="Front-End & Data Visualization"
          techs={[
            {
              icon: img4,
              name: "HTML",
            },
            {
              icon: img5,
              name: "CSS",
            },
            {
              icon: img6,
              name: "JavaScript",
            },
            
            {
              icon: img,
              name: "React.js",
            },
            {
              icon: img1,
              name: "Angular.js",
            },
            {
              icon: img2,
              name: "Vue.js",
            },
            {
              icon: img7,
              name: "Power BI",
            },
            {
              icon: img3,
              name: "D3.js",
            },
            {
              icon: img8,
              name: "Tableau",
            },
            
          ]}
        />
        <EachTechCard
          title="Backend & Cloud"
          techs={[
            
            
            {
              icon: backend6,
              name: "Node.js",
            },
            {
              icon: backend3,
              name: "Django",
            },
            
            {
              icon: backend7,
              name: "Kotlin",
            },
            
            {
              icon: backend5,
              name: "JAVA",
            },
            {
              icon: backend,
              name: "Xcode",
            },
            {
              icon: flask,
              name: "Flask",
            },
            {
              icon: design7,
              name: "AWS Cloud",
            },
            {
              icon: design8,
              name: "Azure Cloud",
            },
            
            {
              icon: design9,
              name: "Google Cloud",
            },
          ]}
        />
        <EachTechCard
          title="Mobile Apps"
          techs={[
            {
              icon: mobile4,
              name: "Flutter",
            },
            {
              icon: mobile,
              name: "React Native",
            },
            {
              icon: mobile1,
              name: "Swift",
            },
            {
              icon: mobile2,
              name: "Android",
            },
            {
              icon: Kivy,
              name: "Kivy",
            },
            
            
            // {
            //   icon: backend7,
            //   name: "Kotlin",
            // },
            {
              icon: ionic,
              name: "MS Power Apps",
            },
            
            
           
          ]}
        />
        <EachTechCard
          title="Databases"
          techs={[
            {
              icon: design,
              name: "MySQL",
            },
            {
              icon: design4,
              name: "DynamoDB",
            },
            
            
            {
              icon: design10,
              name: "IBM DB2",
            },
            {
              icon: design2,
              name: "MongoDB",
            },
            {
              icon: design5,
              name: "SQL Lite",
            },
            
            {
              icon: design3,
              name: "Cassandra",
            },
            {
              icon: oracleupdate,
              name: "Oracle",
            },
            // {
            //   icon: design8,
            //   name: "Azure SQL Database",
            // },
            {
              icon: uday  ,
              name: "PostgreSQL",
            },
            
            
            {
              icon: design1,
              name: "Microsoft SQL Server",
            },
            
          ]}
        />
      </div>
    </div>
  );
};
export default Technologies;

const EachTechCard = ({
  techs,
  title,
}: {
  title: string;
  techs: {
    icon: string;
    name: string;
  }[];
}) => {
  return (
    <div className="techContainer">
      <p className="eachCardTitleTech">{title}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 30,
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {techs.map((item, index) => {
          return (
            <EachSmallCard key={index} icon={item.icon} name={item.name} />
          );
        })}
      </div>
    </div>
  );
};

const EachSmallCard = ({ icon, name }: { icon: string; name: string }) => {
  return (
    <div
      style={{
        width: "100px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={icon}
        alt=""
        style={{
          height: 64,
          width: 64,
        }}
      />
      <p className="eachTechText">{name}</p>
    </div>
  );
};
