import "./index.css";

import React from "react";

import whyWorkImage from "../../../Assets/updated/why works.jpeg";

const WhyWork = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
      }}
    >
      <div id="about"></div>
      <p className="title2">
      Why Aityl(Add It To Your Life)?
      </p>
      <div className="whyWork2" style={{}}>
        <div className="workImage" style={{ flex: 1 }}>
          <img 
            alt="why work with us?"
            src={whyWorkImage}
            style={{
              // marginLeft:"2%",
              // width: "43vw",
              // height:"52vh",
              width:"100%",
              paddingTop:"4%",
              height:"100%"
              
            }}
          />
        </div>
        <div className="whyWorkTextContainer" style={{ flex:1.4 }}>
          <p className="page2Text">
          <span className="underline">Tailored Mobile Apps Development:</span> Dive into the realm of custom mobile applications, where each project is a unique blend of creativity and technical prowess. Whether it’s iOS or Android, we bring your vision to life with state-of-the-art technology.

          <p className="page2Text">
            <span className="underline">Advanced Web Development:</span> We create websites that are not just visually appealing but also highly functional, and optimized for user engagement. Our web development strategy focuses on creating responsive designs that adapt to all devices, ensuring a seamless user experience.
          </p>


          </p>
          
          <p className="page2Text">
          <span className="underline">Comprehensive Data Solutions:</span> Our expertise extends to Data Migration and Analytics, offering you insights and strategies to drive your business forward. We help you make sense of your data, transforming it into a powerful tool for decision-making
          </p>

          
        </div>
      </div>
    </div>
  );
};
export default WhyWork;
