import "./index.css";

import React from "react";

import mobileImg from "../../Assets/robobgremove.png";

import top1 from "../../Assets/updated/Mobile App development.jpeg";
import top2 from "../../Assets/updated/Web development.jpg";
import top3 from "../../Assets/Data Migration.jpeg";
import top4 from "../../Assets/updated/Data Analytics.jpeg";
import top5 from "../../Assets/iOS App Development.jpeg";
import top6 from "../../Assets/Web Development.jpeg";
import WhyWork from "./whyWork";
import OurService from "./ourService";
import OurValues from "./OurValues";
import Technologies from "./TechWeWorked";
import Footer from "./Footer";
import ScrollToTop from "../Navbar/scrollToTop";

const Landing = () => {
  return (
    <div style={{}}>
      <ScrollToTop />
      <div
        className="top1Container"
        style={{
          background: "transparent",
          marginTop: 100,
        }}
      >
        <div
          style={{
            flex: 1,
            height: "100%",
          }}
        >
          <p className="title">Your Gateway to Advanced Mobile Apps</p>
          <p className="title">Development and Data Solutions, in India</p>
          <p className="longtext">
          Embark on a journey with Aityl (Add It to Your Life), one of India's leading apps development startups. We take pride in delivering comprehensive apps development services and data solutions. Our focus is on crafting exceptional, innovative, and cost-efficient solutions.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <EachCard img={top1} text1="Mobile Apps" text2="Development" />
            <EachCard img={top2} text1="Web" text2="Development" />
            <EachCard img={top3} text1="Data" text2="Migration" />
            <EachCard img={top4} text1="Data" text2="Analytics" />
            {/* <EachCard img={top5} text1="Data" text2="engineering " /> */}
            {/* <EachCard img={top6} text1="Data" text2="science" /> */}
          </div>
        </div>
        <div
          style={{
            flex: 0.5,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%",
            marginTop: 100,
          }}
        >
          <img
            src={mobileImg}
            alt="mobile"
            className="breatherImage"
            style={{
              width: "100%",
              // border:"1px solid red",
              marginTop:"-15%"
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: "relative",
          //   bottom: -20,
          height: 72,
          zIndex: 10,
        }}
      >
        <svg className="arrows">
          <path className="a1" d="M0 0 L30 32 L60 0"></path>
          <path className="a2" d="M0 20 L30 52 L60 20"></path>
        </svg>
      </div>
      <WhyWork />
      <OurService />
      <OurValues />
      <Technologies />
      <Footer />
    </div>
  );
};
export default Landing;

const EachCard = ({
  img,
  text1,
  text2,
}: {
  img: string;
  text1: string;
  text2: string;
}) => {
  return (
    <div
      className="eachCardContainer"
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 96,
        marginBottom: 10,
        padding: 0,
        borderRadius: 12,
      }}
    >
      <div
        style={{
          marginRight: text1 === "Data" ? 10 : 10,
          marginLeft: text1 === "Data" ? 10 : 10,
        }}
      >
        <img
          style={{
            marginLeft:"7px",
            width: text1 === "Data" ? 60 : 50,
            borderRadius:12,
            // height: text1 === "Data" ? 60 : 80,
          }}
          src={img}
          alt="1"
        />
      </div>
      <div className="cardContainer">
        <div>
          <p className="cardText">{text1}</p>
        </div>
        <div>
          <p className="cardText">{text2}</p>
        </div>
      </div>
    </div>
  );
};
