import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "99vh",
      }}
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {
          // @ts-ignore
          <i>{error.statusText || error.message}</i>
        }
      </p>
      <Link
        to={{
          pathname: "/",
        }}
      >
        Go Back to Home
      </Link>
    </div>
  );
}
