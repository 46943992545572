import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

import "./index.css";
import ScrollToTop from "./scrollToTop";

const NavBarComponentPrivacy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (isOpen) {
      const body = document.getElementById("body");
      if (body) body.style.overflowY = "hidden";
    } else {
      const body = document.getElementById("body");
      if (body) body.style.overflowY = "scroll";
    }
  }, [isOpen]);

  const Drawer = () => {
    return (
      <>
        {/* {isOpen && <div className="overlay" onClick={toggleDrawer}></div>} */}
        <div
          // className={`side-drawer ${isOpen ? "open" : ""}`}
          // onClick={toggleDrawer}
          // style={{ backgroundColor: "#0d1d34" }}
        >
          {/* Close icon on top right */}
          {/* <div className="close-icon" onClick={toggleDrawer}>
            &#10006;
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              paddingTop: 40,
              // alignItems: "center",
              // gap: 10,
            }}
          >
            {/* <div className="eachNav">
              <a className="textD" onClick={toggleDrawer} href="/">
                Home
              </a>
            </div> */}
            {/* <div className="eachNav">
              <a className="textD" onClick={toggleDrawer} href="/PrivacyPolicy">
                Privacy Policy
              </a>
            </div> */}

            {/* <div className="eachNav">
              <a className="textD" onClick={toggleDrawer} href="/Termsofuse">
                Terms of use
              </a>
            </div> */}

            
            {/* <div className="eachNav">
              <a className="textD" onClick={toggleDrawer} href="/PrivacyPolicy">
                Privacy Policy
              </a>
            </div> */}
            {/* <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <GetQuote />
            </div> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <nav
      style={{
        height: "100%",
        paddingLeft: 100,
        paddingRight: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          {/* <Logo /> */}
        </div>
        <div className="navBarItemContainer" style={{}}>
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              // gap: 10,
            }}
          >
            {/* <div className="eachNav">
              <a className="textD" href="/">
                Home
              </a>
            </div> */}
          </div>
          {/* <div>
            <GetQuote />
          </div> */}
        </div>
        <div
          // className="hamburger"
          style={{
            width: 28,
            height: 28,
          }}
        >
          {/* <button
            style={{
             
            }}
            // onClick={toggleDrawer}
          >
            <svg
              width="28px"
              height="28px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 18L20 18"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M4 12L20 12"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M4 6L20 6"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button> */}
        </div>
      </div>
      <Drawer />
    </nav>
  );
};
export default NavBarComponentPrivacy;

const Logo = () => {
  return (
    <>
      <a  href="/" style={{textDecoration:"none",fontSize:"2rem",color:"black"}}>
        <img
          src={require("../../Assets/New Horse.png")}
          alt=""
          style={{
            height: 53,
          }}
        />
        <b>AITYL</b>
      </a>
    </>
  );
};

const GetQuote = () => {
  return (
    <div
      className="quote"
      style={{
        borderRadius: 4,
      }}
    >
      Get a quote
    </div>
  );
};
