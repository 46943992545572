import React from "react";
import "./index.css";

const PrivacyPolicy = () => {
  return (
    <div className="Privavy-Main">
      <h2>Privacy Policy</h2>
      <p>
        At <a  href="https://www.aityl.com" style={{textDecoration:"none"}} >aityl.com</a>, we prioritize and respect your privacy.
      </p>
      <p>
        Our Privacy Policy outlines the information gathered by our company, aityl.com, and our
        partners through our mobile applications and the use of such information.
      </p>
      <p>
        If you have any inquiries about our privacy policy, please reach out to us at  
        <a href="mailto:aitylgames@gmail.com" style={{textDecoration:"none"}}> aitylgames@gmail.com</a>
      </p>
      <ul>
        <li>We refrain from utilizing third-party advertising.</li>
        <li>No data sharing with any third party occurs.</li>
        <li>We do not collect device data beyond third-party analytics purposes.</li>
        <li>
          Our in-house ads for our own apps are displayed through the "more/other apps" section and
          new apps pop-ups.
        </li>
        <li>
          Unity Analytics monitors app installations and in-app purchase data, specifically
          mentioned in the App privacy section on each app's store listing page.
        </li>
      </ul>
      <h2>Data Collection by Third Parties in Apps with Ads</h2>
      <p>
        When using our Apps, certain information is automatically received, including data about
        your device, software, operating system, language settings, Advertising IDs for
        advertisement tracking, and IP address.
      </p>
      <p>
        We do not gather personally identifiable information, such as names, addresses, phone
        numbers, or email addresses, through our apps.
      </p>
      <p>
        Any personal information voluntarily provided by you (e.g., email addresses for support
        requests) may be collected occasionally.
      </p>
      <p>
        For in-app purchases, we do not access or collect your name and billing information; this
        data is managed and stored by your platform (Google Play for Android or the App Store for
        iOS) or the platform's payment processor.
      </p>

      <p>
        Some of our apps may use Google Analytics (third-party scripts), collecting and potentially
        storing information as cookies on your device, including:
      </p>
      <ul>
        <li>Demographics (browser language, country, city)</li>
        <li>System information (browser, OS, service provider)</li>
        <li>Mobile details (OS, service provider, screen resolution)</li>
        <li>Site behavior (visited pages, duration of visit)</li>
      </ul>
      <p>For more details on our partners' practices, please visit the provided links:</p>
      <ul>
        <li>
          <a href="https://www.google.com/policies/privacy/partners/" style={{textDecoration:"none"}}>Google Privacy Policy</a>
        </li>
        <li>
          <a href="https://policies.google.com/privacy" style={{textDecoration:"none"}}>Google Privacy</a>
        </li>
        <li>
          <a href="https://unity3d.com/legal/privacy-policy" style={{textDecoration:"none"}}>Unity Privacy Policy</a>
        </li>
        <li>
          <a href="https://inmobi.com/privacy-policy/" style={{textDecoration:"none"}}>InMobi Privacy Policy</a>
        </li>
      </ul>
      <h2>Terms of Use</h2>
      <p>How We Utilize Information Collected in Our Apps</p>

      <p>
        We collect this information to enhance the performance and reliability of our apps and gain
        a better understanding of general user preferences. None of this information is linked in
        any way to individuals or any other data we may acquire. We do not share, sell, or provide
        this information to other individuals or companies, and we do not rent or sell your email
        address.
      </p>

      <p>
        Our mobile applications adhere to the Children's Online Privacy Protection Act ("COPPA"). If
        a user identifies as a child under the age of 13 through a support request or feedback, we
        will neither collect, store, nor use any personal information of such a user and will
        securely delete it.
      </p>

      <p>
        We take precautions to filter sensitive and inappropriate ad categories for children.
        However, there may be rare instances where advertisers misclassify ads, which is beyond our
        control.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        <a href="https://www.aityl.com" style={{textDecoration:"none"}}>aityl.com</a> reserves the right to update this privacy
        policy at any time. When we do, we will modify the updated date at home page.
        Users are encouraged to regularly check this page for any changes to stay informed about how
        we protect the collected personal information. You acknowledge and agree that it is your
        responsibility to periodically review this privacy policy and stay aware of any
        modifications.
      </p>
      <p>For more information, please visit the following links:</p>
      <ul>
        <li>
          <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" style={{textDecoration:"none"}}>
            Apple iTunes Developer Standard EULA
          </a>
        </li>
        <li>
          <a href="https://play.google.com/about/play-terms/index.html" style={{textDecoration:"none"}}>
            Google Play Terms of Service
          </a>
        </li>
      </ul>
      <h2>Cookies</h2>
      <p>
        When you visit our website, we may send cookies to your computer that uniquely identify your
        browser. We use cookies to enhance our service quality by storing user preferences and
        tracking usage information. Most browsers allow you to disable cookies or set your browser
        to alert you when cookies are being sent to your computer.
      </p>
    </div>
  );
};

export default PrivacyPolicy;