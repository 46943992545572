import React from "react";
import "./index.css";

import service1 from "../../../Assets/Android.jpeg";
import service2 from "../../../Assets/updated/iOS App Development.jpeg";
import service3 from "../../../Assets/Hybrid.jpeg";
import service4 from "../../../Assets/Web Development.jpeg";
import service5 from "../../../Assets/updated/Data Migration.jpeg";
import service6 from "../../../Assets/updated/Data Analytics.jpeg";

import app1 from "../../../Assets/app1.jpg";
import app2 from "../../../Assets/app2.png";
import app3 from "../../../Assets/app3.png";
import app4 from "../../../Assets/app4.png";
import app5 from "../../../Assets/app5.png";
import app6 from "../../../Assets/app6.png";
import playStore from "../../../Assets/123 (2).png";
import iosicon from "../../../Assets/123 (1).png";


const OurService = () => {
  return (
    <>
      <div id="Services"></div>
      <div
        className="orserviceContainer"
        style={{
          display:"flex",
          flex:"column",
          backgroundColor: "white",
          height: "100%",
          
          paddingTop:"4%"
        }}
      >
        <div
          style={{
            flex: 0.25,
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            <span className="span1">OUR SERVICES IN A </span>
            <span className="span1">NUTSHELL</span>
          </p>
        </div>
        <div
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ServiceCard
            icon={service1}
            title="Android Apps Development"
            text="Explore the potential of low-maintenance Android apps with limitless possibilities"
          />
          <ServiceCard
            icon={service2}
            title="iOS Application Development"
            text="Captivate a broad audience with our top-tier iOS apps development services "
          />
          <ServiceCard
            icon={service3}
            title="Hybrid Application Development"
            text="Embrace the trend of single code base apps for Android and iOS, a cost-effective solution"
          />
          <ServiceCard
            icon={service4}
            title="Web Development"
            text="Professional and affordable web development services"
          />
          <ServiceCard
            icon={service5}
            title="Data Migration"
            text="Smooth, secure data transfer with minimal operational impact "
          />
          <ServiceCard
            icon={service6}
            title="Data Analytics"
            text="Transforming data into actionable business insights."
          />
        </div>
      </div>
      <div id="Portfolio"></div>
      <div
        style={{
          backgroundColor: "white",
          margin: 0,
          padding: 30,
        }}
      >
        <p
          style={{
            textAlign: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <span className="span2">OUR </span>
          <span className="span2">WORKS</span>
        </p>
      </div>
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <WorkCard
          logo={app1}
          
          title="Anti Secret Listen in"
          url="https://play.google.com/store/apps/details?id=com.antisecretlistenin.aitylcom"
          color="#313d5a"
          url1=""
        />
        <WorkCard
          logo={app2}
          title="Math Memory Booster"
          url="https://play.google.com/store/apps/details?id=com.mathsmemorybooster.aitylgames"
          color="#313d5a"
          url1=""
        />
        <WorkCard
          logo={app3}
          title="Santa Claus Analog Clocks"
          url="https://play.google.com/store/apps/details?id=com.santaclock.aitylgames"
          color="#313d5a"
          url1=""
        />
        <WorkCard
          logo={app4}
          title="Clever Notification Manager"
          url="https://play.google.com/store/apps/details?id=com.clevernotifications.aityl"
          color="#313d5a"
          url1=""
        />
        <WorkCard
          logo={app5}
          title="Stop Smoking For Family/Self"
          url="https://play.google.com/store/apps/details?id=com.stopsmokingforfamily.aityl"
          color="#313d5a"
          url1=""
        />
        
        <WorkCard
          logo={app6}
          title="ElGordo Christmas Lottery Play"
          url="https://play.google.com/store/apps/details?id=com.elgordoplay.suvarnatechlabs"
          color="#313d5a"
          url1=""
        />
          <WorkCard
          logo={"https://play-lh.googleusercontent.com/QZiYfWEZ_vM2hTTvu9x72PdyJY8ix0_90ii4v5NHytXWusjWZ9M3a8PRrnOWtAk3u-_-=w240-h480-rw"}
          title="Ganpati Clock Live Wallpaper"
          url="https://play.google.com/store/apps/details?id=com.ganeshaclock.aitylgames"
          color="#313d5a"
          url1=""
          
        />
        <WorkCard
          logo={"https://play-lh.googleusercontent.com/SGCynxlVSSCymlwo0XbdQZlRwOtAYjKxJ9j-CG8lqG5W2J-cVEMUGuMQtxYcM7fkCh0H=w240-h480-rw"}
          title="Essays for IELTS, PTE, TOEFL"
          url="https://play.google.com/store/apps/details?id=com.essaysfortopscores.aityl"
          color="#313d5a"
          url1="https://apps.apple.com/us/app/essays-for-ielts-pte-toefl/id6476619447"
        />
        <WorkCard
          logo={"https://play-lh.googleusercontent.com/XNdUBDqjXb2iPq1m1Ne7IbvJhfCgu_yPV_DsiYSXIM_10G772_QnRDIsH_Byix7vVdA=w240-h480-rw"}
          title="IELTS Writing Success"
          url="https://play.google.com/store/apps/details?id=com.ieltswritingsuccess.aityl"
          color="#313d5a"
          url1="https://apps.apple.com/us/app/ielts-writing-triumph/id6476814871"
          
        />

      

        
        
      </div>
    </>
  );
};
export default OurService;

const ServiceCard = ({
  icon,
  text,
  title,
}: {
  icon: string;
  title: string;
  text: string;
}) => {
  return (
    <div
      className="eachServiceCard"
      style={{
        marginLeft: 20,
      }}
    >
      <img
        src={icon}
        alt="service"
        style={{
          width: 100,
          height: 100,
          borderRadius:"12px"
        }}
      />
      
      <div>
        <p className="serviceCardTitle">{title}</p>
      </div>
      <div>
        <p className="serviceCardText">{text}</p>
      </div>
    </div>
  );
};

const WorkCard = ({
  logo,
  title,
  url,
  color,
  url1
}: {
  logo: string;
  url: string;
  title: string;
  color: string;
  url1:string;
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minWidth: 240,
        maxWidth: 400,
        alignItems: "center",
        backgroundColor: color,
        borderRadius: 25,
        padding: 10,
        marginBottom: 20,
      }}
    >
      <img
        src={logo}
        alt=""
        style={{
          width: "36%",
          borderRadius: 50,
        }}
      />
      <div>
        <p
          style={{
            color: "rgb(249, 247, 244)",
            fontSize: 16,
          }}
        >
          {title}
        </p>
      </div>
      <div style={{display:"flex",
          flexDirection:"row"
    }}>
      {url && (<a
        href={url}
        target="_blank"
        referrerPolicy="no-referrer"
        rel="noreferrer"
      >
        <img
          src={playStore}
          
          
          alt=""
          style={{
            width: 140,
            height: 54,
            marginRight:5,
            margin:-3,
            borderRadius:7,
            // justifyContent:"center",
            cursor: "pointer",
          }}
        />
        </a>)}
        {url1&&(<a href={url1}
        target="_blank"
        referrerPolicy="no-referrer"
        rel="noreferrer">
         <img
          src={iosicon}
          
          
          alt=""
          style={{
            width: 138,
            height: 50,
            marginRight:5,
            borderRadius:7,
            // justifyContent:"center",
            cursor: "pointer",
          }}
        />
        

        {/* Uncomment the below code for the visibility of IOS ICON */}
         {/* <img
          src={iosicon}
          
          alt=""
          style={{
            width: 70,
            height: 65,
            borderRadius:7,

            cursor: "pointer",
          }}
        /> */}
      </a>)}
      </div>
    </div>
  );
};
