import "./index.css";

import React, { useRef, MutableRefObject, useState, useEffect } from "react";

import emailjs from "@emailjs/browser";

import dmca from "../../../Assets/dmca.png";
import copyright from "../../../Assets/copyright1.png";
const isTouchScreenDevice = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};
const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768 && isTouchScreenDevice;
};
const FinalFooter = () => {
  const recipientEmail = "aitylgames@gmail.com";
  const copyright = "Copyright @2023 aityl.com";
  const isMobile = useCheckMobileScreen();
  const openMailtoLinkMobile = () => {
    const mailtoLink = `mailto:${recipientEmail}`;

    // Open the default email client
    window.location.href = mailtoLink;
  };
  return (
    <div className="bg">
      <div id="Contact"></div>
      <p
        style={{
          textAlign: "center",
        }}
      >
        <span className="span4" >Let's Create Something Amazing Together!</span>
        <br />
        <span className="span5">Send Us an Email:</span>
        <br />
          <span className="span5">We Respnd Promptly:</span>
      </p>
      <br />
      <div>
      <a className="textD" style={{
          textAlign: "center",
          fontSize: "16px",
          border: "1px solid white",
          marginRight:"5px",
          padding: "10px 15px",
          borderRadius: "15px",
        }} href="/privacy-Policy-2" target="_blank">
          Privacy Policy
      </a>


      <a className="textD uday" style={{
        marginTop:"5px",
          textAlign: "center",
          fontSize: "16px",
          border: "1px solid white",
          padding: "10px 15px",
          borderRadius: "15px",
        }} href="/Terms-of-Us" target="_blank">
          Terms of Use
      </a>
      </div>
      


      {isMobile ? (
        <button
          onClick={() => openMailtoLinkMobile()}
          style={{
            border: "0px",
            backgroundColor: "transparent",
            padding: 0,
            
          }}
        >
          <p
            style={{
              fontSize: 24,
              color: "white",
              cursor: "pointer",
              
            }}
          >
            {recipientEmail}
            

          </p>
          
        </button>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${recipientEmail}&su=&body=`}
          style={{
            border: "0px",
            backgroundColor: "transparent",
            padding: 0,
            textDecoration:"none"
          }}
        >
          <p
            style={{
              marginTop:"2rem",
              fontSize: 24,
              color: "white",
              cursor: "pointer",
              textDecoration:"none"
            }}
          >
            {recipientEmail}
            <br/><br/>
           

          </p>
        </a>
        
      )}
      <p style={{color:"white", marginTop:"-1rem"}}>Copyright @Aityl(Add It To Your Life) 2024</p>

      <br />
      
      
      {/* <p style={{color:"white", marginTop:"-1rem"}} className="span6"> 
      The trademarks, logos, and brand names mentioned on this website are the property of their respective owners. Any company, product, or service names used here are for identification purposes only. <br />
      <br />The inclusion of these names, trademarks, and brands does not signify endorsement.</p> */}

     <span className="span5" id="color"  style={{
          textAlign: "center",
          fontSize: "16px",
          // border: "1px solid white",
          marginRight:"5px",
          padding: "10px 15px",

          
        }} >The trademarks, logos, and brand names mentioned on this website are the property of their respective owners.    Any company, product, or service names used here are for identification purposes only</span>
    

    </div>
    
  );
};

const ActualFooter = () => {
  const form: MutableRefObject<HTMLFormElement | null> = useRef(null);
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.current)
      emailjs
        .sendForm(
          "service_zx7so99",
          "template_pe1m72c",
          form.current,
          "JSD0iddL3UHZyagdy"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
  };
  return (
    <div className="bg">
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
          }}
        >
          <span className="span4">Contact </span>
          <span className="span3">Us</span>
        </p>
      </div>
      <form
        ref={form}
        onSubmit={(e) => sendEmail(e)}
        style={{
          display: "flex",
          backgroundColor: "white",
          maxHeight: 44,
        }}
      >
        <input
          type="email"
          placeholder="Email"
          // onChange={(text) => (form.current = text.target.value)}
          name="user_email"
        />
        <button
          type="submit"
          onClick={() => {
            console.log("clicked");
          }}
          style={{
            height: 44,
            width: 44,
            backgroundColor: "white",
            position: "relative",
            left: -5,
            cursor: "pointer",
            border: "0px",
          }}
        >
          <svg viewBox="0 0 512.005 512.005" style={{ margin: 10 }}>
            <path d="M511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184a15 15 0 00-.054 28.006L145 298.8v164.713a15 15 0 0028.396 6.75l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669C516.947 34.532 511.431 52.726 511.658 51.675zm-118.981 52.718L157.874 271.612 56.846 232.594zM175 296.245l204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546l-120.296-89.276 217.511-229.462z"></path>
          </svg>
        </button>
      </form>
    </div>
  );
};

const Footer = () => {
  return (
    <div
      style={{
        paddingTop: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
      }}
    >
      <div className="footerAllContainer" style={{}}>
        <div className="footerSocialCOntainer" style={{}}>
          <Social />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div className="footerNonSocial">
            <p className="footerTitle">Useful Links</p>
            <p className="footerText">About us</p>
            <p className="footerText">App Development Company in Pune</p>
            <p className="footerText">App Development Company in Mumbai</p>
            <p className="footerText">App Development Company in Delhi</p>
            <p className="footerText">App Development Company in Gurugram</p>
            <p className="footerText">App Development Company in Bangalore</p>
            <p className="footerText">App Development Company in Ahmedabad</p>
            <p className="footerText">App Development Company in Noida</p>
          </div>
          <div className="footerNonSocial">
            <p className="footerTitle">Our Services</p>
            <p className="footerText">Privacy policy</p>
            <p className="footerText">Website Development</p>
            <p className="footerText">UI/UX Designing</p>
            <p className="footerText">Digital Marketing</p>
            <p className="footerText">SEO/SMM Services</p>
            <p className="footerText">Android App Development</p>
            <p className="footerText">iOS App Development</p>
            <p className="footerText">Hybrid App Development</p>
          </div>
          <div className="footerNonSocial">
            <p className="footerTitle">Contact Us</p>
            <p className="footerTitle">
              Office No #331, 2nd Floor, 18 Latitude Business Complex, Kate
              Wasti Road, Punawale, Hinjawadi, Pune, Maharashtra 411033.
            </p>
            <p className="footerTitle">Phone: +91 7385024414</p>
            <p className="footerTitle">Email: info@appconsultio.com</p>
          </div>
        </div>
      </div>
      <div
        className="footerSocialCOntainer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="copyrightFooter">
          <span className="copyrightFooterLight">© Copyright </span>
          <span className="copyrightFooterDark">appconsultio.</span>
          <span className="copyrightFooterLight">
            {" "}
            All Rights Reserved @2023 | sitemap
          </span>
        </p>
      </div>
    </div>
  );
};

export default FinalFooter;

const Social = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <p className="footerText1">
          Appconsultio is a leading provider of mobile app development and
          consulting services. We assist businesses of all sizes and industries
          in developing and launching successful mobile apps. Our team of
          experienced developers and consultants works closely with clients to
          understand their needs and deliver solutions that yield results.
        </p>
      </div>
      <div></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <div>
          <img
            src={copyright}
            alt=""
            style={{
              width: 119,
              height: 102,
            }}
          />
        </div>
        <div>
          <img
            src={dmca}
            alt=""
            style={{
              width: 121,
              height: 24,
            }}
          />
        </div>
      </div>
    </div>
  );
};
