import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import NavBarComponent from "./components/Navbar";
import BgImg from "./Assets/Background.png";
import Landing from "./components/landing";
import { createBrowserRouter, RouterProvider, BrowserRouter, Routes, Route, } from "react-router-dom";
import ErrorPage from "./components/Error";
import PrivacyPolicy from "./components/PrivacyPolicy";
import NavBarComponentPrivacy from "./components/Navbar/index_privacy";
import Home from "./components/Homepage";
import path from "path";
import Termsofuse from "./components/Navbar/Termsofuse/Index";
const App = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setTimeout(() => {
          setScrolled(isScrolled);
        }, 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div>
          <div
            style={
              {
                // paddingLeft: 100,
                // paddingRight: 100,
              }
            }
            className={`navbar ${scrolled ? "scrolled" : ""}`}
          >
            <NavBarComponent />
          </div>
          <div
            style={
              {
                // paddingLeft: 100,
                // paddingRight: 100,
              }
            }
            className="content hero"
          >
            <Landing />
          </div>
        </div>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/Terms-of-Us",
      element: (
        <div>

<div
            style={
              {
                // paddingLeft: 100,
                // paddingRight: 100,
              }
            }
            className={`navbar ${scrolled ? "scrolled" : ""}`}
          >
            <NavBarComponentPrivacy />
          </div>
          
         
          <div
            style={
              {
        
              // display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              //   // backgroundColor: "#0d1d34",
              //   // color: "rgba(255, 255, 255, 0.7)",
              //     paddingLeft: 100,
              //   paddingRight: 100,
              //   paddingTop:100,
              
              }
            }
          
          >
            <Termsofuse />
          </div>
        </div>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/privacy-Policy-2",
      element: (
        <div>
          <div
            style={
              {
                // paddingLeft: 100,
                // paddingRight: 100,
              }
            }
            className={`navbar ${scrolled ? "scrolled" : ""}`}
          >
            <NavBarComponentPrivacy />
          </div>
          <div
            style={
              {
                // paddingLeft: 100,
                // paddingRight: 100,
              }
            }
            className="content hero"
          >
            <PrivacyPolicy />
          </div>
         
        </div>
      ),
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default App;
