import React from "react";

import "./index.css";
import values1 from "../../../Assets/our_values/customer_satisfaction.png";
import values2 from "../../../Assets/our_values/finalised.jpeg";
import values3 from "../../../Assets/our_values/secure.jpeg";
import values4 from "../../../Assets/our_values/ontime.jpeg";

const OurValues = () => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#313d5a",
        // paddingLeft: 100,
        // paddingRight: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: 40,
        paddingBottom: 40,
      }}
    >
      <OurValuesTitle />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <EachCard image={values1} text="Customer Satisfaction" />
        <EachCard image={values2} text="Ontime Delivery" />
        <EachCard image={values3} text="Secured Information" />
        <EachCard image={values4} text="Customer Support" />
      </div>
    </div>
  );
};
export default OurValues;

const OurValuesTitle = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginBottom:"1rem"
      }}
    >
      <p>
        <span className="ourvaluestext"
        style={{
          
        }}
        >
          OUR
        </span>
      </p>
      <span
        className="ourvaluestext2"
        style={{
          
        }}
      >
        VALUES
      </span>
    </div>
  );
};

const EachCard = ({ text, image }: { image: string; text: string }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        width: 200,
      }}
    >
      <img
        src={image}
        alt=""
        style={{
          width: 110,
          height: 110,
          borderRadius:8
        }}
      />
      <p className="valuesCardText">{text}</p>
    </div>
  );
};
