import React, { useState, useEffect } from "react";
import whatsApp from "../../../Assets/whatsapp.png";
import up from "../../../Assets/up-arrowlight.png";
import "./index.css"; // You may need to adjust this import based on your project structure and styling preferences

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    // Add event listener to track scroll position
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 200; // Adjust this threshold based on when you want the icon to appear
      setShowScroll(scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <div
        className={showScroll ? "scroll-to-topVisible" : "scroll-to-top"}
        onClick={scrollToTop}
      >
        <img src={up} alt="" style={{ width: 22, height: 22 }} />
      </div>
      {/* <div className="whatsapp-icon">
        <img src={whatsApp} alt="" style={{ width: 65, height: 65 }} />
      </div> */}
    </div>
  );
};

export default ScrollToTop;
